.card.fact-card {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    border-radius: 16px;
}

.company-facts {
    flex: auto;
    padding: 0 30px;
}

.company-facts:first-child {
    padding-left: 15px;
}
.company-facts:last-child {
    padding-right: 15px;
}

.company-facts + .company-facts {
    border-left: 1px solid #D1D1D1;
}
.company-facts .fact-header h6 {
    font-size: 14px;
    font-weight: 400;
}

.company-facts .fact-header  h2 {
    font-size: 28px;
    font-weight: 700;
}

.company-facts .fact-header  img {
    max-width: 44px;
    max-height: 44px;
}

.company-facts .fact-body {
    margin-top: 15px
}

.company-facts .fact-body ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.company-facts .fact-body ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.company-facts .fact-body ul li p {
    font-size: 14px;
    color: #767676;
}

.company-facts .fact-body ul li p strong {
    font-weight: 700;
}