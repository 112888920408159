.blog-listing {
    display: flex; 
    flex-direction: column;
    gap: 15px;
    width: 100%;
    margin-top: 10px;
}

.s-blog {
    border-radius: 16px;
    padding: 16px;
    border: 1px solid rgb(43 43 43 / 20%);
    display: flex;
    gap: 15px;
    width: 100%;
}

.s-blog .blog-img {
    padding: 0;
}

.s-blog .blog-img img {
    width: auto;
    max-width: 190px;
    height: 100px;
    object-fit: cover;
}

.s-blog .blog-desc {
    flex: auto;
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.s-blog .blog-desc .blog-extras {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.s-blog .blog-desc .blog-content h6 {
    font-size: 12px;
    color: inherit;
    margin: 0;
}

.s-blog .blog-desc .blog-content h2 {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0;
}

.s-blog .blog-desc .blog-content {
    color: rgb(43 43 43 / 50%);
}

.s-blog .blog-desc .blog-content p {
    margin-bottom: 8px !important;
    font-size: 12px;
}

.blog-upload-box {
    border: 1px solid #003866;
    border-radius: 16px;
    padding: 16px 24px;
    margin-top: 20px;
}

.blog-upload-box .page-title-heading h4 {
    font-weight: 700;
    font-size: 20px !important;
}

.blog-upload-box .form-control, .blog-upload-box .form-select {
    background: rgb(221 222 238 / 25%);
    border-radius: 8px;
    border: 0;
    font-size: 13px;
    padding: 10px;
    margin-top: 6px;
}

.blog-upload-box label {
    color: #2B2B2B;
    font-size: 14px;
    font-weight: 700;
}

.blog-upload-box .form-control::placeholder {
    opacity: 50%;
}

.blog-upload-box .candidate-res-file {
    height: auto;
    text-align: center;
    background: #fff;
    border: 1px dashed #2B2B2B;
    border-width: 2px;
}

.blog-upload-box .candidate-res-file .btn-outline-black {
    font-weight: 400;
    padding: 6px 30px;
    border-radius: 4px;
}

.blog-upload-box .job-des-copy-paste .ql-editor {
    height: 535px !important;
}

.blog-upload-box p.candidate-file-res-drop {
    font-weight: 400;
}