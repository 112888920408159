/********profile candidate ********/

.profile-card {
  width: 100%;
  flex: 0 0 100%;
  box-shadow: 0px 4px 32px 6px rgba(0, 0, 0, 0.05);
  background: var(--color-white);
  border-radius: 16px;
  position: relative;
  padding: 20px;
  min-height: 200px;
  color: var(--dark-black-color);
  margin-bottom: 16px;
}

.profile-card .edit-btn-profile {
  position: absolute;
  right: 0;
  top: 0;
}

.profile-card .edit-btn-profile img {
  max-width: 20px;
}

.profile-card .s-amenity {
  margin-bottom: 36px;
}

.profile-card .s-amenity h3,
.profile-card .f-feild label {
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
}

.profile-card .s-amenity .tags-tittles ul.tags-titles-list {
  margin: 0;
}

.profile-card .video-react {
  height: 300px !important;
  padding: 0 !important;
}

.profile-action-btn .btn {
  position: relative;
  padding: 8px 10px !important;
}

.profile-action-btn .btn #file-input-btn {
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.candidtae-profile-image-avatar {
  width: 180px;
  height: 180px;
}


.candidtae-profile-image-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100%;
}

.profile-row {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  justify-content: flex-end;
}

.candidate-information h5 {
  font-family: var(--arial-mains);
  font-weight: var(--font-weight-bold);
  font-size: 28px;
  padding: 0 10px 0 0;
}

p.candidate-info-text {
  color: var(--dark-black-color);
  font-size: 16px;
}

.candiate-info-heading {
  padding: 10px 0 0;
}

.candiate-info-heading a {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.candiate-info-heading a img {
  width: 22px !important;
  height: 22px !important;
  object-fit: contain;
}

.profile-edit-body h3 {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 15px;
  margin: 0;
}

.profile-edit-body .edit-btn-profile img {
  max-width: 20px;
}

.profile-edit-body p {
  font-size: 16px;
}

.profile-edit-body .edit-footer {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: end;
  flex-wrap: wrap;
}

.profile-edit-body .edit-footer .btn-ai {
  border-radius: 25px;
}

.profile-edit-body .edit-footer .btn {
  font-weight: 400;
  padding: 10px 15px;
  min-width: 150px;
}

.profile-edit-body .form-control {
  background: #F3F4F8;
}

.profile-edit-body .form-control+p {
  font-size: 12px;
  margin-top: 5px;
}

.profile-edit-body .edit-footer .action-edit {
  display: flex;
  align-items: center;
  gap: 10px;
}

section.profile-edit {
  padding: 100px 0px 20px 0px;
}

.profile-edit-row {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.profile-heading h2 {
  display: flex;
  justify-content: center;
  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: 32px;
  align-items: flex-end;
}

.profile-heading h2 span {
  font-size: 20px;
  padding-left: 5px;
}

.my-profile-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 33px;
  padding-bottom: 50px;
  flex-wrap: wrap;
}

.my-profile-row-date-fill {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.profile-sec-view {
  flex: auto;
}

.ml-0 {
  margin-left: 0 !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.theme-line {
  border-top: 1px solid #143975;
  opacity: 1;
}

.candidate-information {
  max-width: calc(100% - 195px);
  flex: auto;
}

.candidate-information.with-stack-btns {
  max-width: calc(100% - 195px - 24%);
  flex: auto;
}

.employee-profile-compnay-data {
  padding: 30px 0 15px 0px;
}

p.company-name-profile-date {
  font-size: 24px;
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-mains);
}

p.employee-profile-company-content-date {
  font-size: 16px;
  font-weight: 300;
  font-family: var(--arial-mains);
  color: var(--text-color);
  line-height: 26px;
  text-align: justify;
}

.about-me-form {
  display: none;
}

.candidate-profile-form {
  border-left: 1px solid rgba(189, 189, 189, 0.50);
}

.form-block-width {
  max-width: 590px;
  padding: 28px 20px;
  position: relative;
}

.form-block-width+.form-block-width:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-top: 1px solid rgba(189, 189, 189, 0.50);
}

.accordion-candidate-prfile .accordion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-candidate-prfile .accordion-button .plus-icon {
  transform-origin: center;
  transition: 0.25s all ease;
}

.accordion-candidate-prfile .accordion-button:not(.collapsed) .plus-icon {
  transform: rotate(135deg);
}

.candidate-profile-btn {
  max-width: 24%;
  width: 100%;
}

.p-0 {
  padding: 0 !important;
}

.accordion-candidate-prfile .accordion-button {
  font-size: 16px !important;
}



.candidtae-profile-image {
  max-width: 17%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  height: 180px;
}

.profile-img-c {
  display: none;
}

.candidtae-profile-image input {
  display: none;
}

label#candidate-img {
  text-align: center;
}

label#candidate-img img {
  cursor: pointer;
}

.indicator-icon-pro {
  position: absolute;
  right: 10px;
}

p.candidate-logo-file {
  color: var(--dark-black-color);
  font-size: var(--button-text);
  font-weight: var(--font-weight-bold);
  opacity: 0.5;
  font-family: var(--arial-mains);
  line-height: 19px;
  margin: 8px 0px;
}

p.file-size-candidate {
  color: var(--dark-black-color);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  opacity: 0.5;
  font-family: var(--arial-mains);
  line-height: 17px;
  margin: 8px 0px;
}

p.file-size-selected-candidate {
  color: var(--dark-black-color);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-mains);
  line-height: 17px;
  margin: 8px 0px;
}

p.file-name-candidate {
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-mains);
  line-height: 17px;
  margin: 8px 0px;
}

.three-stack-btn button {
  max-width: 100%;
  width: 100%;
  padding: 10px 0;
  background: transparent;
  margin-bottom: 8px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 8px;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-mains);
  outline: 0;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.three-stack-btn button:hover {
  background: var(--primary-color);
  color: var(--color-white);
}

.three-stack-btn button input {
  display: none;
}

p.file-btn {
  cursor: pointer;
}

.edit-profile-icon,
.tab-content-hide {
  display: none
}


/* candidate-profile tabs*/

section.candidate-profile-tabs {
  padding-bottom: 100px;
}

.tabs-candidate-col-space {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 0px auto 50px;
}

.space-col-left {
  max-width: 17%;
  width: 100%;
}

.space-col-center {
  max-width: 60%;
  width: 100%;
}

.space-col-right {
  max-width: 23%;
  width: 100%;
}

.accordion-button::after {
  display: inline-block;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item {
  padding-bottom: 20px;
  border: 0px solid rgba(0, 0, 0, .125);
  background: transparent;
}

.accordion-button:focus {
  z-index: unset;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

h2#headingOne:focus-visible {
  outline: 0;
}

button.accordion-button.collapsed {
  color: var(--dark-black-color);
  font-size: 24px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 25px;
  padding: 0 10px;
  background: transparent;
}

button.accordian-profile-res {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.accordian-profile-res .plus-icon {
  transition: 0.25s all ease;
}

button.accordian-profile-res:not(.collapsed) .plus-icon {
  transform: rotate(135deg);
  transform-origin: center;
}

/* .accordion-button:not(.collapsed) {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-mains);
  margin-bottom: 25px;
  padding: 0 20px;
  background: transparent;
  box-shadow: none;
} */

textarea#candidate-profile-tabs {
  width: 100%;
  border: 0.5px solid rgb(0 0 0 / 30%);
  border-radius: 6px;
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--arial-mains);
  background-color: rgba(221, 222, 238, 0.25) !important;
  padding: 15px;
}

.upload-intro-vedio input {
  display: none;
}

.upload-intro-vedio {
  min-height: 212px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(221, 222, 238, 0.25) !important;
  border: 0.5px solid rgb(0 0 0 / 30%);
  border-radius: 6px;
}

p.candidate-profile-tabs-msg {
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--arial-mains);
  font-weight: var(--font-weight-medium);
  text-align: end;
}

.save-cancel-btn {
  display: flex;
  justify-content: end;
  gap: 25px;
  margin-top: 20px;
}

button.cancel-btn-profile {
  font-size: 16px;
  padding: 10px 0px;
  background: transparent;
  border: 1px solid var(--dark-black-color);
  color: var(--dark-black-color);
  border-radius: 8px;
  font-family: var(--arial-mains);
  min-width: 115px;
}

button.save-btn-profile {
  font-size: 16px;
  padding: 10px 0px;
  background: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 6px;
  font-family: var(--arial-mains);
  min-width: 115px;
}

button.save-btn-profilefill {
  font-size: 16px;
  padding: 10px 57px;
  background: var(--dark-black-color);
  border: 1px solid var(--primary-color);
  color: var(--color-white);
  border-radius: 8px;
  font-family: var(--arial-mains);
}

button.save-btn-profile:hover {
  background: var(--primary-color);
  color: var(--color-white);
}

.back-save-btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  max-width: 44%;
  margin: 0 auto;
}

button.profile-end-btn {
  font-size: var(--promoted-btn);
  padding: 12px 66px;
  background: transparent;
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
  border-radius: 6px;
  font-family: var(--arial-mains);
}

button.profile-end-btn:hover {
  background: var(--accent-color);
  border: 1px solid var(--accent-color);
  color: var(--color-white);
}

.preferences-row {
  padding: 10px 0px 20px;
}

.preferences-row h5 {
  padding: 0;
}

.preferences-row h2 {
  font-weight: var(--font-weight-bold);
}

.accordion-candidate-prfile .accordion-item {
  border: 1px solid #EFF0F4;
  border-radius: 8px;
  padding: 16px;
}

.accordion-candidate-prfile .accordion-item+.accordion-item {
  margin-top: 40px;
}


input#preferences-prfile {
  width: 100%;
  padding: 13px 8px;
  border: 0.5px solid var(--boder-color);
  border-radius: 6px;
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--arial-mains);
  background-color: rgba(221, 222, 238, 0.25) !important;
}

.divider-left-profile {
  border-right: 00.5px solid var(--boder-color);
}

.highlightMatchingText {
  color: #143975
}

.btn-height-profile {
  max-height: 50px;
}

/************profile form fill *************/

.candidate-name-field-profile {
  display: flex;
  gap: 20px;
}

.first-name-profile {
  max-width: 50%;
  width: 100%;
}

input#candidate-name-field,
input#candidate-lname-field {
  /* max-width: 50%; */
  width: 100%;
  padding: 13px 10px;
  border: 0.5px solid #BDBDBD;
  border-radius: 6px;
}

select#candidate-location-feild {
  max-width: 100%;
  width: 100%;
  padding: 18px 10px;
  border: 0.5px solid #BDBDBD;
  border-radius: 6px;
}

input#candidate-name-field:focus-visible,
input#candidate-lname-field:focus-visible,
select#candidate-location-feild:focus-visible,
input#profile-email-candidate:focus-visible,
select#candidate-location-feild:focus,
input#Company-name-employe-profile,
input#profile-url-employee {
  outline: 0;
}

.profile-field-f {
  margin-bottom: 20px;
}

.profile-heading {
  flex: auto;
}

input#profile-email-candidate {
  max-width: 90%;
  width: 100%;
  display: block;
  padding: 0 10px 0 10px !important;
  border: 0;
}

select#candidate-location-feild {
  background: url(/public/image/resume-location.png);
  background-repeat: no-repeat;
  display: block;
  background-size: 20px;
  padding-left: 40px !important;
  background-position: left 10px bottom 20px;
}

.upload-can-pro-msg {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0px;
  color: var(--pacific-blue-color) !important;
}