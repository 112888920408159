.other-options {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.chat-on {
  position: relative;
  border: 1px solid #2B2B2B;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.chat-on img {
  max-width: 24px;
}

.chat-on span {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2B2B2B;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  bottom: calc(100% - 11px);
  left: calc(100% - 11px);
  border-radius: 100%;
}

.chat-op {
  opacity: 0.5;
}

.divider {
  border-top: 1px solid var(--text-color);
  opacity: 0.2;
}

.divider-two {
  border-top: 1px solid var(--text-color);
  opacity: 0.2;
  width: 100%;
}

.divider-opcity {
  border-top: 1px solid var(--text-color);
  opacity: 0.2;
}

.job-title a.title-job-link h3 {
  text-decoration: none;
  max-width: 100%;
  font-size: 18px;
}

.job-title h3 {
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
  margin-bottom: 0px;
  max-width: 100%;
}

.job-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 15px;
}

.job-discription {
  color: rgba(43 43 43 / 70%);
  font-size: 16px;
  font-weight: 300;
  font-family: var(--arial-main);
  padding: 15px 0 36px 0;
  text-align: justify;
  line-height: 28px;
  min-height: 135px;
  height: auto;
}

.job-discription p {
  display: inline;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.job-skill-location {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.job-services-anchor p {
  color: var(--text-color);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.job-time {
  display: flex;
  justify-content: space-between;
}

.job-time p {
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

.job-location p {
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

.job-sec-wrape {
  margin: 0 10px;
}

.job-heading {
  padding: 24px 0;
  background: var(--pacific-blue-color-300);
}

.job-heading h1 {
  font-family: var(--source-pro);
  color: var(--dark-black-color);
  text-align: center;
  font-weight: var(--font-weight-semibold);
  font-size: 38px;
  margin: 0;
  line-height: 1;
}

.job-heading h2 {
  font-family: var(--source-pro);
  color: var(--dark-black-color);
  text-align: center;
  font-weight: var(--font-weight-semibold);
  font-size: 28px;
  margin: 0;
  line-height: 1;
}

.job-heading h1+p {
  margin-top: 16px;
  font-size: 24px;

}

.job-heading+.divider {
  opacity: 0;
}

.Job-Opportunities-row {
  display: flex;
  padding: 30px 0px 30px 0px;
  justify-content: space-between;
  width: 100%;
}

.job-services img {
  height: 35px;
  width: 35px;
  object-fit: contain;
}

.job-services {
  display: flex;
  align-items: center;
  gap: 10px;
}

.job-services-anchor a {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.job-services-anchor img {
  height: 35px;
  width: 35px;
  object-fit: contain;
}

.job-title {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: auto;
  flex-wrap: wrap;
  max-width: 70%;
}

.job-seclude {
  display: flex;
  flex-wrap: wrap;
  max-width: 50%;
  width: 100%;
  justify-content: space-between;
}

.job-skills {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 60%;
  flex-wrap: wrap;
}

.job-skills button {
  background: #f2f7ff;
  color: var(--dark-black-color);
  font-size: var(--skill-text-size);
  border: 0.5px solid #e2edff;
  font-weight: var(--font-weight-medium);
  padding: 4px 8px;
  border-radius: 16px;
}

.job-location {
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  /* max-width: 35%; */
  /* width: 100%; */
  justify-content: end;
  padding-top: 5px;
}

.job-location-new {
  display: flex;
  gap: 5px;
  align-items: center;
  /* flex-wrap: wrap; */
  /* max-width: 35%; */
  width: 200px;
  flex: none;
  justify-content: end;
  padding-top: 5px;
}

.job-location img {
  height: 18px;
  width: 18px;
  object-fit: cover;
  max-width: 100%;
}

.job-title a.title-job-link {
  text-decoration: none;
  max-width: 100%;
}

.job-announcements-status p {
  padding: 15px 0px 0px;
  font-size: 16px;
  color: var(--text-color);
  font-weight: var(--font-weight-medium);
  opacity: 0.5;
}

.job-favorite {
  display: flex;
  gap: 10px;
  font-size: 16px;
  color: #64a7b6;
  font-weight: var(--font-weight-medium);
}

.job-favorite img {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.job-applied-text {
  display: flex;
  gap: 10px;
  font-size: 16px;
  color: var(--text-color);
  font-weight: var(--font-weight-medium);
  opacity: 0.8;
}

.job-applied-text img {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.job-announcements-col button {
  background: var(--color-grey);
  border: 2px solid var(--dark-black-color);
  border-radius: 8px;
  color: var(--dark-black-color);
  padding: 8px 18px;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.job-announcements-col button.btn-transparent {
  border: 0;
}

.job-announcements-col a {
  text-decoration: none;
}

.location-job-table img {
  height: 18px;
  width: 18px;
  object-fit: cover;
  max-width: 100%;
}

.location-job-table p {
  color: var(--text-color);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

.job-apply {
  display: flex;
  gap: 15px;
  padding: 16px 0px;
  color: var(--accent-color);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.job-time-wishlist {
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
}

.job-time-wishlist.job-app {
  margin-top: 45px;
}

.job-time-wishlist .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 15px;
  gap: 4px;
  font-weight: 600;
}

.btn-outline-blue {
  background: transparent;
  border-color: #2B819D;
  color: #2B819D;
  border-radius: 8px;
}

.btn {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
}

.btn-lg {
  font-size: 20px;
}

.btn-outline-black {
  background: var(--color-white);
  border-width: 2px;
  border-color: var(--dark-black-color);
  color: var(--dark-black-color);
}

.btn-outline-black:hover {
  background: var(--color-white);
  border-color: var(--dark-black-color);
  color: var(--dark-black-color);
}

.btn-black {
  background: var(--dark-black-color);
  border-color: var(--dark-black-color);
  color: var(--color-white);
  border-radius: 8px;
}

.btn-black:focus,
.btn-black:hover {
  background: var(--dark-black-color);
  border-color: var(--dark-black-color);
  color: var(--color-white);
}

.btn-outline-purple {
  background: transparent;
  border-color: #143975;
  color: #143975;
  border-radius: 8px;
}

.btn-outline-blue:hover {
  background: transparent;
  border-color: #2B819D;
  color: #2B819D;
}

.btn-outline-purple:hover {
  background: transparent;
  border-color: #143975;
  color: #143975;
}


.job-announcements {
  width: 240px;
  position: relative;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.job-announcements .btn-holder {
  margin-top: 60px;
  height: calc(100% - 170px);
}

.no-fav {
  height: 28px;
}

.job-announcements .btn-holder .btn-group-holder {
  height: 114px;
}

.job-requriments {
  width: calc(100% - 290px);
  position: relative;
}

.job-title a.promoted-btn-not {
  text-decoration: none;
  max-width: 100%;
}

.museo-font {
  font-family: var(--arial-main);
}

.job-skills .tag {
  background-color: var(--Whisper-color);
}

.promoted-job-btn {
  border-radius: 4px;
  background: transparent;
  color: var(--pacific-blue-color);
  padding: 8px;
  font-weight: 600;
  border: none;
  border: 1px solid var(--pacific-blue-color);
  line-height: 1;
}

.job-companyName {
  cursor: pointer;
}

/*****************Switch Button***********/

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  /* Set background to transparent */
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid rgba(0, 0, 0, 0.5);
  /* Set border color to black */
  border-radius: 34px;
  /* Add border-radius for rounded shape */
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  top: 50%;
  /* Vertically center the circle */
  transform: translateY(-50%);
  /* Vertically center the circle */
  background-color: red;
  /* Set default circle color to red */
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  /* Add border-radius for rounded shape */
}

input:checked+.slider {
  border-color: black;
  /* Change border color to black when checked */
}

input:checked+.slider:before {
  background-color: green;
  /* Change circle color to green when checked */
  -webkit-transform: translateX(20px) translateY(-50%);
  /* Move circle to the right and keep it vertically centered */
  -ms-transform: translateX(20px) translateY(-50%);
  transform: translateX(20px) translateY(-50%);
}

.job-eployee-table-col p {
  padding: 15px 0px 0px;
  font-size: 16px;
  color: var(--dark-black-color);
  font-weight: var(--font-weight-medium);
  padding-bottom: 5px;
}

.job-eployee-table-col p.day {
  opacity: 0.5;
}

.top-5 {
  top: 2rem !important;
}

.top-1 {
  top: .5rem !important;
}

.left-3 {
  left: 1rem !important;
}

.job-location-switch {
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  max-width: 40%;
  width: 100%;
  position: relative;
  justify-content: space-between;
}

.loction-switch-col {
  text-align: end;
  padding-right: 10px;
}

.location-job-table {
  display: flex;
  align-items: center;
  gap: 5px;
}

.job-candidate-list-icon {
  display: flex;
  gap: 15px;
  padding: 15px 0px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.job-description-mg {
  margin-top: -10px;
}

.currency-mg {
  margin-left: 5px;
  margin-right: 2px;
}

.hiring-status-box{
  position: absolute;
  min-width: 289px;
  background-color: #fff;
  top: 25px;
  right: -20px;
  border: 1px solid rgba(43, 43, 43, 0.3);
  border-radius: 12px;
  padding: 16px;
}

.hiring-status-box > h3 {
font-family: Arial;
font-size: 18px;
font-weight: 700;
line-height: 22px;
}

.hiring-status-box > p {
font-family: Arial;
font-size: 14px;
font-weight: 400;
line-height: 16px;
}

.status-content{
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-box{
  position: relative;
}

.tow-btn-job-dash-draft{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.job-date {
  font-size: 16px;
  text-align: right;
  color: rgba(43, 43, 43, 0.7);
}

@media only screen and (max-width:1050px) {
  .job-seclude-mbl {
    max-width: 100% !important;
    justify-content: space-between !important;
  }

  .currency-mg {
    margin-left: 20px;
    margin-right: 5px;
  }
}

@media(max-width: 767px) {
  .hiring-status-box {
    position: fixed;
    z-index: 9;
    right: 10px;
    min-width: max-content;
    width: calc(100% - 20px);
    left: 10px;
    top: 10px;
  }
}