/*********  students resume template   *******/

.res-std-location {
  font-family: var(--Time-new-roman);
  font-weight: 400;
  color: var(--dark-black-color);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}

.res-mail-std {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 5px 0 0px 0;
  justify-content: center;
}

.res-linkedin-std {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 5px 0 20px 0;
  justify-content: center;
}

.res-mail-std a,
.res-mail-std span,
.res-linkedin-std a,
.res-linkedin-std span {
  font-family: var(--Time-new-roman);
  font-weight: 400;
  color: var(--dark-black-color);
  font-size: 16px;
  text-decoration: none;
  word-break: break-all;
  line-height: 18px;
}

.std-heading {
  text-align: left;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  padding-bottom: 5px;
}

.resume-job-list-std {
  font-family: var(--Time-new-roman);
  font-weight: 400;
  color: var(--dark-black-color);
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  padding: 5px 0 0 0px;
}

.std-right-coma {
  padding: 0px 5px 0px 0px;
}
