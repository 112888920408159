/****EXecutive resume template****/

.resume-info-exe {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding: 5px 0px;
}

.res-exe-phone,
.res-mail-exe {
    display: flex;
    align-items: flex-start;
    gap: 5px;
}

.res-exe-phone a {
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--dark-black-color);
    font-size: 16px;
    text-decoration: none;
    word-break: break-all;
    line-height: 18px;
}

.res-mail-exe a {
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--dark-black-color);
    font-size: 16px;
    text-decoration: none;
    word-break: break-all;
    line-height: 18px;
}

.res-mail-exe ul {
    display: flex;
    margin: 0;
    gap: 25px;
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--dark-black-color);
    font-size: 16px;
    text-decoration: none;
    word-break: break-all;
    line-height: 18px;
}

.exe-heading {
    text-align: left !important;
}

.resume-summar-exe {
    padding: 30px 0 0 0;
}

.resume-exe-bdr {
    border: 1px solid #2B2B2B
}

.sub-heading-res-right-exe {
    font-family: var(--Time-new-roman);
    font-weight: var(--font-weight-medium);
    color: var(--dark-black-color);
    font-size: 18px;
    line-height: 24px;
}

.resume-job-list-exe {
    font-family: var(--Time-new-roman);
    font-weight: 500;
    color: var(--dark-black-color);
    font-size: 16px;
    line-height: 25px;
    text-align: justify;
    padding: 15px 0 0 0px;
}

.resume-job-list-exe span {
    font-weight: 500;
}

.exe-sub-heading {
    padding-top: 15px;
}

.time-comp-res-exe {
    display: flex;
    justify-content: flex-start;
    padding: 5px 0 0px 0;
    align-items: center;
}

.edu-uni-exe {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.exe-right-weight {
    font-weight: 400 !important;
}

.exe-right-pipe {
    padding: 0px 5px;
}

.education-res-exe {
    padding: 15px 0;
}

.resume-edu-exe,
.city-edu-exe {
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--dark-black-color);
    font-size: 16px;
    line-height: 24px;
}

.uni-res-exe {
    font-family: var(--Time-new-roman);
    font-weight: 700;
    color: var(--dark-black-color);
    font-size: 16px;
    line-height: 24px;
}


/***********/