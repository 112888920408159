
  
  .title {
    text-align: center;
    margin: 20px 0;
    font-size: 2.5rem;
    color: black;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  .error {
    color: #ff6b6b;
    text-align: center;
    font-weight: bold;
  }
  
  .video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 32px;
    padding: 0px;
    max-width: 1300px;
    margin: 0 auto;
    border-radius: 12px;
    backdrop-filter: blur(8px);
  }
  
  .video-card {
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 0;
  }
  .video-card .yt-thumbnail {
    height: 200px;
  }
  .video-card .yt-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .video-card:hover {
    transform: translateY(-5px);
  }
  
  .thumbnail {
    width: 100%;
    display: block;
  }
  
  .video-info {
    padding: 10px;
    text-align: center;
  }
  
  .video-title {
    font-size: 1.1rem;
    margin: 0;
    color: #333;
  }

  .yt-banner {
    border-radius: 24px;
  }

  .blog-img{
    padding: 60px ;
  }

  .rt-row{
    padding: 100px;
  }

  .rt-row > h2 {
    font-family: Arial;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
  }

  .pt-blog{
    font-family: Arial;
    font-weight: 400;
    font-size: 16px;
    opacity: 80%;
    margin-top: 12px;
  }

  .read-more-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;  
    color: black; /* Blue text */
    background-color: transparent; /* Transparent background */
    border: 1px solid black; /* Blue border */
    border-radius: 8px; /* Pill-shaped */
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
    cursor: pointer;
  }
  
  .read-more-button:hover {
    background-color: black; /* Blue background on hover */
    color: white; /* White text on hover */
  }
  
  .read-more-button:active {
    background-color: black; /* Darker blue when clicked */
    border-color: black; /* Darker border when clicked */
  }
  
  /* Overlay Styles */
  .video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlay-content {
    position: relative;
    width: 80%;
    max-width: 900px;
    height: 60%;
    background: #000;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  }
  
  .video-player {
    border: none;
    width: 100%;
    height: 100%;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .close-btn:hover {
    background-color: #c0392b;
  }

  .yt-banner {
    background: rgba(243, 247, 250, 1);
  }
  .yt-banner .yt-content {
    position: relative;
  }
  .yt-banner .yt-content .vc-img {
    position: absolute;
    max-width: 240px;
  }
  .yt-banner .yt-content .vc-img.img-top {
    right: 5vw;
    bottom: 100% 
  }
  .yt-banner .yt-content .vc-img.img-bottom {
    right: 11vw;
    top: calc(100% + 10px);
  }
  

  .blog-grid .video-title {
    color: #2b2b2b
  }
  .blog-grid .video-title + p {
    color: #2b2b2b
  }


  
/* Mobile Responsive CSS */
@media (max-width: 768px) {
  .yt-banner .row {
    flex-direction: column;
  }

  .yt-banner .col-md-6 {
    width: 100%;
  }

  .blog-img {
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .rt-row {
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .rt-row > h2 {
    font-size: 22px; /* Adjust font size for smaller screens */
    line-height: 28px;
  }

  .pt-blog {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .read-more-button {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }

  .yt-banner .yt-content .vc-img {
    max-width: 150px; /* Adjust image size for smaller screens */
  }

  .yt-banner .yt-content .vc-img.img-top {
    right: 10px; /* Adjust position for smaller screens */
    bottom: 90%;
  }

  .yt-banner .yt-content .vc-img.img-bottom {
    right: 20px; /* Adjust position for smaller screens */
    top: calc(100% + 5px);
  }
}


