/********skills form verfication resume*****/

.experince-res-ver-right-skills {
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
  border-radius: 12px !important;
  border: 1px solid rgba(0, 0, 0, 0.16) !important;
  background: var(--color-white);
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.06);
  position: relative;
}

button.resume-skills-right-tb {
  border-top: 0px solid rgba(0, 0, 0, 0.16) !important;
  background: var(--color-white) !important;
  padding: 0px 20px 20px 15px !important;
  margin: 0 !important;
  border-radius: 12px !important;
  display: block !important;
}

.tags-skills ul.tags-skill-list {
  list-style: none;
  display: flex;
  gap: 8px;
  margin: 8px 0 0px 0;
  padding: 0;
  flex-wrap: wrap;
  width: 100%;
}

.tags-skills ul.tags-skill-list li {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 0.5px solid #f2f7ff;
  background: #e2edff;
  padding: 4px 8px;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  font-family: var(--arial-main);
}

.tags-skills ul.tags-skill-list li img {
  border: 1px solid var(--primary-color);
  border-radius: 38px;
  height: 15px;
  width: 15px;
  padding: 2px;
}

.right-resume-skill {
  font-weight: var(--font-weight-medium);
  color: var(--dark-black-color);
  font-size: 16px;
  padding-bottom: 0px;
}

.compay-name-job-resume-right.skill-resume-right {
  max-width: 70%;
  width: 100%;
}

.date-job-res.skill-resume-left {
  max-width: 30%;
}
