.cl-logo{
    max-width: 120px;
    max-height: 120px;
    padding: 24px 16px
}

.c-detail-text > h3{
            font-size: 24px;
            font-weight: 700;
    }

    .c-detail-text > p{
            font-size: 16px;
            font-weight: normal;
            opacity: 80%;
            text-align: justify;
            padding: 0 24px 0 0;
}

.c-detail{
    padding: 10px;
}