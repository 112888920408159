/********experince tab form**********/

.experince-resume-right {
  padding: 20px 0 0px 0;
}

.experince-resume-right .accordion-button {
  font-family: var(--arial-main);
}

.experince-resume-right .accordion-button::after {
  content: none;
}

.experience-info-right {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 24px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  border-top: 1px solid rgb(0 0 0 / 20%);
  padding-top: 15px;
}

.experience-info-right img {
  max-height: 32px;
}

.accordion-body.educ-res-right {
  padding: 0 !important;
}

.job-title-resume-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
}

.compay-name-job-resume-right {
  max-width: 50%;
  width: 100%;
}

.date-job-res {
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 50%;
  justify-content: end;
  width: 100%;
  margin-right: 45px;
}

.date-job-res p {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 14px;
}

img.del-res {
  cursor: pointer;
}

img.del-res-skill {
  cursor: pointer;
}

p.right-resume-cmp {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 16px;
  padding-bottom: 8px;
}

p.job-title-res-right {
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: 400;
}

p.job-pro-url-res-right {
  font-weight: 500 !important;
  color: var(--dark-black-color);
  font-size: 14px;
  word-break: break-word;
}

p.job-pro-url-res-right-inner {
  text-decoration: underline;
}

/* .accordion-button:not(.collapsed)::after,
.accordion-button.collapsed::after {
  background-image: none;
  display: none;
} */

button.data-tab-res-right {
  padding: 14px 10px !important;
  border-radius: 16px !important;
  background: var(--color-white) !important;
  margin-bottom: 0 !important;
}

button.resume-exper-right-tb {
  border-top: 1px solid rgba(0, 0, 0, 0.16) !important;
  background: var(--color-white) !important;
  padding: 0px 15px 20px 15px !important;
  margin: 0 !important;
  border-radius: 0 0 16px 16px !important;
  display: block !important;
}

.resume-right-f-date {
  max-width: 50%;
  width: 100%;
}

.date-range-date-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;
}

.list-search-dropdown-year-resume {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 49%;
  overflow: auto;
  z-index: 4;
  margin: 5px 0 0 auto;
  right: 0;
  top: 90%;
  max-height: 185px;
  height: auto;
  display: none;
}

.Year-res-v.open .list-search-dropdown-year-resume {
  display: block;
}

.icon-hide-res {
  max-width: 18%;
  width: 100%;
  top: -3px;
  position: relative;
}

.icon-hide-res img {
  cursor: pointer;
}

.month,
.Year-res-v {
  display: flex;
  align-items: center;
  border: 0.5px solid var(--boder-color);
  border-radius: 6px;
  background: rgba(221, 222, 238, 0.25);
  width: 100%;
  text-align: center;
  -webkit-appearance: none;
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  color: var(--dark-black-color);
}

.accordion-button:hover {
  z-index: unset;
}

div#fresume-year {
  padding: 15px 8px;
  justify-content: space-between;
}

.list-search-dropdown-month-resume {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 50%;
  overflow: auto;
  z-index: 4;
  margin: 5px 0 0 auto;
  left: 0;
  top: 90%;
  max-height: 185px;
  height: auto;
  display: none;
}

.month.open .list-search-dropdown-month-resume {
  display: block;
}

/* width */

.list-search-dropdown-month-resume::-webkit-scrollbar {
  width: 8px;
}

/* Track */

.list-search-dropdown-month-resume::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.list-search-dropdown-month-resume::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--dark-black-color);
}

/* width */

.list-search-dropdown-year-resume::-webkit-scrollbar {
  width: 8px;
}

/* Track */

.list-search-dropdown-year-resume::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.list-search-dropdown-year-resume::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--dark-black-color);
}

ul.list-job-title-year-res {
  list-style: none;
  margin-bottom: 0;
  padding: 15px 10px;
}

ul.list-job-title-year-res li {
  border-radius: 8px;
  background: var(--color-white);
  padding: 0px 10px 0 2px;
  cursor: pointer;
  color: rgb(0 0 0 / 50%);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  line-height: 40px;
  text-align: left;
}

ul.list-job-title-year-res li:hover {
  border-radius: 6px;
  background: #F2F7FF;
  color: var(--dark-black-color);
  font-weight: var(--font-weight-medium);
}

.present-chek {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  gap: 4px;
  color: var(--dark-black-color);
}

input#job-res:checked {
  accent-color: var(--dark-black-color) !important;
}

div#fresume-year {}

.disabl-res-f {
  opacity: 0.5;
}

.experince-res-ver-right {
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
  border-radius: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.16) !important;
  background: var(--color-white);
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.06);
  position: relative;
}

.border-box-clr-res {
  border: 1px solid #FFA033 !important;
}

.resume-personal-form-chek {
  display: flex;
  padding-top: 0px;
}

.end-date-res-ver {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.two-can-sav-btn {
  display: flex;
  justify-content: end;
  gap: 15px;
}

button.can-res-btn {
  border-radius: 6px;
  border: 1px solid var(--dark-black-color);
  background: var(--color-white);
  color: var(--dark-black-color);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  min-width: 131px;
  padding: 14px 37.5px 15px;
}

button.save-res-btn {
  border-radius: 6px;
  border: 1px solid var(--dark-black-color);
  background: var(--dark-black-color);
  color: var(--color-white);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  min-width: 131px;
  padding: 14px 37.5px 15px;
}


/*******al suggestion resume***/

.btn-resume-magic {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resume-check-ai {
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
}

input.ai-suggestion-resume {
  margin-right: 4px;
}

.check-box-resumme {
  padding: 20px;
  border-radius: 8px;
  background: #DDDEEE40;
  border: 0.5px solid #00000029;
  margin-top: 15px;
}

.check-box-f-res {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding-bottom: 10px;
}

label.checkbox-label-resume {
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-black-color);
}

input.ai-resume-resume {
  margin-top: 6px;
}

input.ai-resume-resume,
input.ai-suggestion-resume {
  accent-color: var(--dark-black-color);
}

label.project-des{
  font-weight: 400 !important;
  line-height: 22px;
  text-align: justify;
}

