/***right side bar headder********/

.header-sidebar {
  padding: 15px 60px 15px 20px;
  background: var(--color-white);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
}

.row-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col-left-sidebar{
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding-left: 25px;
}

.right-content-sidebar {
  padding: 0px;
  background: #F9F9F9;
}

.row-header {
  display: flex;
  justify-content: space-between;
}

.dropdown-dashbord-admin {
  max-width: 100%;
  width: 300px;
  margin: 0 0 0 auto;
  position: absolute;
  top: 60px;
  right: 45px;
  z-index: 2;
  padding-right: 10px;
}

.dropdown-dashbord-admin ul {
  background: #FCFDFD;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  list-style: none;
  padding: 20px 24px 24px 24px;
  z-index: 1;
}

.dropdown-dashbord-admin ul a {
  font-size: 16px;
  font-family: var(--arial-main);
  font-size: var(--font-weight-medium);
  text-decoration: none;
  color: var(--dark-black-color);
  line-height: 20px;
  transition: font-weight 0.3s ease;
}

.text-header-sidebar {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  justify-content: end;
  align-items: center;
}

.text-header-sidebar p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.40);
  cursor: pointer;
  font-family: var(--arial-main);
}

.text-header-sidebar span.active {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--dark-black-color);
  padding-left: 5px;
  cursor: pointer;
  font-family: var(--arial-main);
}

.col-right-sidebar {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px 50px;
}

.department-name-img a {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.department-name-img img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

p.department-name {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 14px;
  line-height: 20px;
  font-family: var(--arial-main);
}

.toggle-img-admin{
 margin-left: 10px;
}

.all-flag-sidebar {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.all-flag-sidebar .btn-outline-black.dropdown-toggle {
  border-width: 1px;
  font-weight: 500;
}
.all-flag-sidebar .btn-outline-black.dropdown-toggle:after {
  color: #000;
}
.all-flag-sidebar .btn-outline-black.dropdown-toggle img {
  max-width: 20px;
}

.headder-flag-sidebar {
  position: relative;
}

.flag-list {
  text-align: center;
  cursor: pointer;
}

.flag-list img {
  text-align: center;
  opacity: 0.4;
  height: 30px;
  width: 30px;
}

.flag-list.active::after {
  content: '';
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: var(--primary-color);
  margin: 0 auto;
  left: 0;
  bottom: -14px;
  right: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  visibility: visible;
  /* transform-origin: right top;
  transform: scale(0, 1);
  transition: color 0.1s, transform 0.2s ease-out; */
}

.flag-list.active img {
  opacity: 1;
  transform: scale(1.1);
}


/* .flag-list.active:hover::after {
  visibility: visible;
} */

.flag-caption {
  position: absolute;
  top: 58px;
}

.flag-caption.active {
  position: absolute;
  top: 50px;
  left: -30px;
  color: var(--dark-black-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  font-family: var(--arial-main);
  visibility: visible;
  white-space: nowrap;
}

.flag-caption {
  position: absolute;
  top: 70px;
  left: -14px;
  color: var(--dark-black-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  font-family: var(--arial-main);
  visibility: hidden;
}